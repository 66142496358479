import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    loading: false,
    userID: false,
    snackbar: {
      display: false,
      color: "",
      text: "",
      timeout: 5000,
    },
    selectedInventoryListing: "",
  },

  mutations: {
    setLoading: (state, boolean) => (state.loading = boolean),
    setUserID: (state, userID) => (state.userID = userID),
    setSnackbar: (state, values) => {
      state.snackbar.display = values.display;
      state.snackbar.color = values.color;
      state.snackbar.text = values.text;
      state.snackbar.timeout = values.timeout || 5000;
    },
    setSelectedInventoryListing: (state, values) =>
      (state.selectedInventoryListing = values),
  },

  actions: {
    setLoading({ commit }, boolean) {
      commit("setLoading", boolean);
    },
    setUserID({ commit }, userID) {
      commit("setUserID", userID);
    },
    setSnackbar({ commit }, values) {
      commit("setSnackbar", values);
    },
    setSelectedInventoryListing({ commit }, values) {
      commit("setSelectedInventoryListing", values);
    },
  },
});
