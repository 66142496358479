<template>
  <v-app>
    <v-system-bar color="pink" dark v-if="browserWarning" class="py-4 py-sm-0">
      <v-icon class="d-none d-sm-block mt-n1" color="white"
        >mdi-information-outline</v-icon
      >
      <v-spacer />
      <span class="white--text font-weight-medium text-caption text-sm-body-2"
        >This website is best viewed using Google Chrome</span
      >
      <v-spacer />
    </v-system-bar>
    <v-main :class="$vuetify.theme.dark ? '' : 'grey lighten-5'" class="pb-8">
      <router-view />
    </v-main>
    <Snackbar />
    <v-footer padless>
      <v-col class="text-center" cols="12">
        &copy; {{ new Date().getFullYear() }} |
        <strong>Silent Auction Vacations</strong>
      </v-col>
    </v-footer>
  </v-app>
</template>

<script>
import Snackbar from "@/components/Snackbar";
export default {
  components: {
    Snackbar,
  },
  data: () => ({
    browserWarning: false,
  }),
  mounted() {
    let browserDetect = this.$browserDetect;
    if (!browserDetect.isChrome && !browserDetect.isChromeIOS)
      this.browserWarning = true;
  },
};
</script>

<style>
/* global styles */
a {
  text-decoration: none;
}
.v-card {
  width: 100% !important;
}
/* removing up and down arrows in number inputs */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* custom scrollbars */
/* ::-webkit-scrollbar,
.scrollBox::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.65rem;
}
::-webkit-scrollbar-track,
.scrollBox::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
::-webkit-scrollbar-thumb,
.scrollBox::-webkit-scrollbar-thumb {
  background-color: #1976d2;
  border-radius: 2px;
} */
/* snackbar */
.v-snack__content {
  font-weight: 500 !important;
}
/* utility classes */
.border {
  border: thin solid rgba(0, 0, 0, 0.12);
}
.border-top {
  border-top: thin solid rgba(0, 0, 0, 0.12);
}
.italic {
  font-style: italic;
}
.text-sm {
  font-size: 14px;
}

.v-toast {
  font-family: "Roboto", "sans-serif";
}
</style>
