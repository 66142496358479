import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import firebase from "./plugins/firebase";
import { firestorePlugin } from "vuefire";

// https://github.com/ankurk91/vue-toast-notification/tree/v1.x
// https://jsfiddle.net/ankurk91/ebakcs62/
import VueToast from "vue-toast-notification";
// use sugar theme for icons
// import "vue-toast-notification/dist/theme-default.css";
import "vue-toast-notification/dist/theme-sugar.css";
Vue.use(VueToast, {
  position: "top-right",
  dismissible: true,
  duration: 5000,
});

// https://www.npmjs.com/package/vue-browser-detect-plugin
import browserDetect from "vue-browser-detect-plugin";
Vue.use(browserDetect);

Vue.use(firestorePlugin);
Vue.config.productionTip = false;

let app;

if (window.location.href.includes("/register")) {
  new Vue({
    router,
    store,
    vuetify,
    render: (h) => {
      return h(App);
    },
  }).$mount("#app");
} else {
  firebase.auth().onAuthStateChanged(async (user) => {
    if (user) {
      const travelerUserAccountsDoc = await firebase
        .firestore()
        .collection("travelerUserAccounts")
        .doc(user.uid)
        .get();

      const travelerUserAccounts = travelerUserAccountsDoc.data();
      if (
        travelerUserAccountsDoc.exists &&
        travelerUserAccounts.accounts.length != 1
      ) {
        const [id] = travelerUserAccounts.accounts.filter((el) =>
          el.includes(`+${travelerUserAccounts.accounts.length - 1}`)
        );
       await store.dispatch("setUserID", id);
      } else {
        await store.dispatch("setUserID", user.uid);
      }
      // await store.dispatch("setUserID", user.uid);
    } else {
      await store.dispatch("setUserID", false);
    }

    if (!app) {
      new Vue({
        router,
        store,
        vuetify,
        render: (h) => {
          return h(App);
        },
      }).$mount("#app");
    }
  });
}
