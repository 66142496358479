<template>
  <!-- snackbar -->
  <v-slide-x-reverse-transition>
    <v-snackbar
      v-model="snackbarDisplay"
      :color="snackbar.color"
      right
      rounded="pill"
      top
      id="snackbar"
      :timeout="snackbar.timeout"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn
          dark
          icon
          v-bind="attrs"
          @click="$store.commit('setSnackbar', { display: false })"
          class="mr-4"
        >
          <v-icon>mdi-close-circle-outline</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-slide-x-reverse-transition>
  <!-- snackbar -->
</template>

 <script>
export default {
  computed: {
    snackbar() {
      return this.$store.state.snackbar;
    },
    snackbarDisplay: {
      get() {
        return this.snackbar.display;
      },
      set() {
        this.$store.commit("setSnackbar", {
          display: false,
        });
      },
    },
  },
  watch: {
    snackbar: {
      immediate: true,
      handler(snackbar) {
        if (snackbar.display) {
          setTimeout(() => {
            this.$store.commit("setSnackbar", { display: false });
          }, snackbar.timeout + 500);
        }
      },
    },
  },
};
</script>

<style>
#snackbar {
  z-index: 1000 !important;
}
</style>