import { render, staticRenderFns } from "./Snackbar.vue?vue&type=template&id=6891b56a&"
import script from "./Snackbar.vue?vue&type=script&lang=js&"
export * from "./Snackbar.vue?vue&type=script&lang=js&"
import style0 from "./Snackbar.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSlideXReverseTransition } from 'vuetify/lib/components/transitions';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
installComponents(component, {VBtn,VIcon,VSlideXReverseTransition,VSnackbar})
