<template>
  <div>
    <!-- hero -->
    <v-container fluid class="pa-0">
      <v-row no-gutters>
        <v-col cols="12">
          <v-img
            src="@/assets/hero.jpg"
            height="600"
            :max-height="$vuetify.breakpoint.smAndDown ? '500' : ''"
            lazy-src="@/assets/lazy-src.jpg"
          >
            <!-- dark mode btn -->
            <v-btn
              absolute
              top
              right
              icon
              @click="$vuetify.theme.dark = !$vuetify.theme.dark"
              color="white"
              style="z-index: 10"
            >
              <v-icon>{{
                $vuetify.theme.dark ? "mdi-lightbulb-outline" : "mdi-lightbulb"
              }}</v-icon>
            </v-btn>
            <!-- dark mode btn -->
            <v-overlay absolute :opacity="$vuetify.theme.dark ? 0.85 : 0.7">
              <v-row align="center" justify="center">
                <v-col class="text-center px-10" cols="12">
                  <h1 class="display-2 font-weight-light mb-4">
                    SAV Traveler Portal
                  </h1>
                  <h4
                    class="body-1 mx-auto font-weight-regular"
                    style="max-width: 767px"
                  >
                    We offer multiple travel programs for non-profit and
                    service. Please review the congratulations email you
                    received for the link to view program details for your
                    specific package type.
                  </h4>
                  <!-- sign in btn -->
                  <v-btn
                    class="mt-5"
                    outlined
                    @click="$router.push('/sign-in')"
                  >
                    <v-icon left>mdi-account-outline</v-icon>
                    Sign In
                  </v-btn>
                  <!-- sign in btn -->
                </v-col>
              </v-row>
            </v-overlay>
          </v-img>
        </v-col>
      </v-row>
    </v-container>
    <!-- hero -->
    <!-- cards -->
    <v-container>
      <v-row class="mt-3">
        <!-- register  -->
        <v-col cols="12" sm="6" md="4" class="d-flex">
          <v-card outlined class="d-sm-flex flex-sm-column">
            <v-card-title>Register Your Package</v-card-title>
            <v-card-text
              >To register your package, click the button below and complete the
              package registration form.</v-card-text
            >
            <v-spacer />
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="primary"
                small
                depressed
                @click="$router.push('/register')"
              >
                Register Package
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <!-- register  -->

        <!-- sign in -->
        <v-col cols="12" sm="6" md="4" class="d-flex">
          <v-card outlined class="d-sm-flex flex-sm-column">
            <v-card-title>Sign in to Your Dashboard</v-card-title>
            <v-card-text>Click the button below to sign in.</v-card-text>
            <v-spacer />
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="primary"
                small
                depressed
                @click="$router.push('/sign-in')"
              >
                Sign in to Your Dashboard
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <!-- sign in -->

        <!-- browse -->
        <!-- <v-col cols="12" sm="6" md="4" class="d-flex">
          <v-card outlined class="d-sm-flex flex-sm-column">
            <v-card-title>Browse Travel Options</v-card-title>
            <v-card-text
              >Click the button below to browse your travel
              options.</v-card-text
            >
            <v-spacer />
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="primary"
                small
                depressed
                @click="$router.push('/sign-in')"
              >
                Browse Travel Options
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col> -->
        <!-- browse -->

        <!-- contact -->
        <!-- <v-col cols="12" sm="6" md="4" class="d-flex">
          <v-card outlined class="d-sm-flex flex-sm-column">
            <v-card-title>Contact Us</v-card-title>
            <v-card-text>Click the button below to contact us.</v-card-text>
            <v-spacer />
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="primary"
                small
                depressed
                @click="$router.push('/contact')"
              >
                Contact Us
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col> -->
        <!-- contact -->
      </v-row>
    </v-container>
    <!-- cards -->
  </div>
</template>

<script>
export default {
  mounted() {
    if (this.$store.state.userID) return this.$router.push("/dashboard");
  },
};
</script>
