import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import store from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    beforeEnter: onlyUsersAllowed,
    path: "/dashboard",
    name: "Dashboard",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function() {
      return import(
        /* webpackChunkName: "dashboard" */ "../views/Dashboard.vue"
      );
    },
  },
  {
    path: "/sign-in",
    name: "SignIn",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function() {
      return import(/* webpackChunkName: "signIn" */ "../views/SignIn.vue");
    },
  },
  {
    path: "/register",
    name: "Register",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function() {
      return import(/* webpackChunkName: "register" */ "../views/Register.vue");
    },
  },
  {
    path: "/contact",
    name: "Contact",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function() {
      return import(/* webpackChunkName: "contact" */ "../views/Contact.vue");
    },
    redirect: "/",
  },
  {
    beforeEnter: onlyUsersAllowed,
    path: "/browse",
    name: "Browse",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function() {
      return import(/* webpackChunkName: "browse" */ "../views/Browse");
    },
  },
  {
    beforeEnter: onlyUsersAllowed,
    params: true,
    path: "/inventory/:id",
    name: "InventoryID",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function() {
      return import(
        /* webpackChunkName: "inventoryID" */ "../views/Inventory/_id.vue"
      );
    },
  },
  {
    path: "/invoice",
    name: "Invoice",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function() {
      return import(/* webpackChunkName: "invoice" */ "../views/Invoice.vue");
    },
  },
  {
    beforeEnter: onlyUsersAllowed,
    path: "/custom-request",
    name: "CustomRequest",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function() {
      return import(
        /* webpackChunkName: "customRequest" */ "../views/AV-Sheet.vue"
      );
    },
  },
  {
    beforeEnter: onlyUsersAllowed,
    params: true,
    path: "/alternative-destinations/:id",
    name: "AlternativeDestinations",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function() {
      return import(
        /* webpackChunkName: "alternativeDestinations" */ "../views/Alternative-Destinations/_id.vue"
      );
    },
  },
  {
    path: "/payments",
    name: "Payments",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function() {
      return import(/* webpackChunkName: "payments" */ "../views/Payments.vue");
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

function onlyUsersAllowed(to, from, next) {
  if (store.state.userID) {
    next();
  } else {
    next("/");
  }
}

export default router;
